import { Button } from '@material-ui/core';
import { Colorize, EventNote, People, Videocam } from '@material-ui/icons';
import { IconCalendarStats, IconPillOff } from '@tabler/icons-react';
import { format, isValid } from 'date-fns';
import { useRouterStore } from 'mobx-state-router';
import React from 'react';

import { generateRouteUrl } from 'src/boot/routes';
import { DrugScreeningStatusLabel } from 'src/components/general/PatientMenu/highlights/DrugScreeningPeriodStatus';
import Fyi from 'src/components/general/PatientMenu/highlights/Fyi';
import { CardDivider } from 'src/myDayToday/components/CardDivider';
import { EventTypeLabel } from 'src/myDayToday/components/EventSubType';
import { EventSubTypeIcon } from 'src/myDayToday/components/EventSubTypeIcon';
import { InformationRow } from 'src/myDayToday/components/InformationRow';
import { MedicationRunOutLabel } from 'src/myDayToday/components/MedicationRunOutLabel';
import { PatientName } from 'src/myDayToday/components/PatientName';
import { useScheduledEventCardStyles } from 'src/myDayToday/components/ScheduledEventCard.styles';
import { ScheduledEventCardHeader } from 'src/myDayToday/components/ScheduledEventCardHeader';
import { StateBadge } from 'src/myDayToday/components/StateBadge';
import { VisitTimeFrame } from 'src/myDayToday/components/VisitTimeFrame';
import { useScheduledEventTiming } from 'src/myDayToday/components/useScheduledEventTiming';
import { EventCardPatient } from 'src/myDayToday/types/EventCardPatient';
import useApolloClient from 'src/shared/client/useApolloClient';
import { SETUP_EVENT_VIDEO_CONFERENCE } from 'src/stores/mutations/events';
import useRootStore from 'src/stores/useRootStore';

type PartialEvent = {
  id: string | null;
  duration: number;
  start: Date;
  subType: string;
  title?: string | null;
  type: string;
};

type ScheduledEventCardProps = {
  currentTime: Date;
  event: PartialEvent;
  patient: EventCardPatient;
  visitReason?: string;
  visitType: string;
};

const ScheduledEventCard = (props: ScheduledEventCardProps) => {
  const classes = useScheduledEventCardStyles();

  const router = useRouterStore();
  const rootStore = useRootStore();
  const apolloClient = useApolloClient();
  const [startVisitError, setStartVisitError] = React.useState<string | null>(null);

  const eventTiming = useScheduledEventTiming(props.currentTime, props.event.start);

  const containerClasses = [classes.container, eventTiming];

  // There are 3 steps here:
  // - First, we create a video conference for the event
  //   (the server won't create a new one if one exists)
  // - Then we need to re-load the event data in the store
  //   otherwise the video conference data won't be seen by
  //   the video conference page
  // - Finally, we open a new tab with the video call and
  //   navigate to the event page in the current tab
  const startVisit = () => {
    setStartVisitError(null);

    apolloClient
      .mutate({
        mutation: SETUP_EVENT_VIDEO_CONFERENCE,
        variables: { id: props.event.id },
      })
      .then(() => rootStore.events.loadEvent(props.event.id))
      .then(() => {
        const videoUrl = generateRouteUrl('eventVc', { event: props.event.id });
        window.open(videoUrl, '_blank');
        router.goTo('showEvent', { params: { id: props.event.id } });
      })
      .catch(e => {
        setStartVisitError(e.message?.length ? e.message : 'Unknown error');
      });
  };

  return (
    <div className={containerClasses.join(' ')} data-testid="scheduled-event-card">
      <div className="header">
        <ScheduledEventCardHeader
          currentTime={props.currentTime}
          start={props.event.start}
          timing={eventTiming}
        />
      </div>
      <div className="body">
        <div className="avatar">
          <img src={props.patient.avatarUrl} />
          <div className="patientInformation">
            <StateBadge state={props.patient.state} />
            <Fyi patientId={props.patient.patientId} />
          </div>
        </div>
        <div className="details">
          <div className="visitType">
            <EventSubTypeIcon subType={props.event.subType} size={18} />
            <EventTypeLabel type={props.event.type} subType={props.event.subType} />
          </div>
          <div className="patientName">
            <PatientName {...props.patient} />
          </div>
          <div className="visitTimeFrame">
            <VisitTimeFrame start={props.event.start} duration={props.event.duration} />
          </div>

          <CardDivider />

          <div className="informationList">
            <InformationRow
              icon={<EventNote />}
              label="Visit note"
              value={props.event.title ?? '--'}
            />
            <InformationRow
              icon={<IconCalendarStats />}
              label="Visit cadence"
              value={props.patient.visitCadence}
            />
            <InformationRow
              icon={<IconPillOff />}
              label="Bupe runout date"
              value={<MedicationRunOutLabel runOutDate={props.patient.medicationRunOutDate} />}
            />
            <InformationRow
              icon={<Colorize />}
              label="Drug screening status"
              value={
                <DrugScreeningStatusLabel
                  screeningStatus={props.patient.drugScreeningStatus}
                  defaultLabel="--"
                />
              }
            />
            <InformationRow
              icon={<People />}
              label="Last visit"
              value={
                // Note for 'as Date': isValid allows us to be sure this is a Date
                isValid(props.patient.lastVisit)
                  ? format(props.patient.lastVisit as Date, 'M/d/yyyy')
                  : null
              }
            />
          </div>

          <CardDivider />

          <div className="actions">
            <div className="error">
              {startVisitError && (
                <span>An error occurred while starting the visit: {startVisitError}</span>
              )}
            </div>
            <Button className="acceptButton" onClick={startVisit}>
              <Videocam />
              Start
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduledEventCard;
