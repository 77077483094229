import { makeStyles } from '@material-ui/core';
import React from 'react';

import Colors from 'src/nightingale/Colors';

export const PatientHeader: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.headerContainer}>
      <div>avatar</div>
      <div>name</div>
      <div>buttons</div>
    </div>
  );
};

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'stretch',
    width: '100%',
    minHeight: 50,
    backgroundColor: Colors.Stormcloud,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: Colors.Gray3,
    position: 'sticky',
    top: 0,
    zIndex: 2,
    padding: '10px 30px 10px 30px',
    gap: 10,
  },
});
