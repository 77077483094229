import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, withStyles } from '@material-ui/core/styles';
import type { ClassNameMap } from '@material-ui/styles';
import React, { useState } from 'react';

import type { VerificationStatus } from 'src/generated/gql/graphql';
import Colors from 'src/nightingale/Colors';
import VerificationStatusIcon from 'src/patientVerification/components/VerificationStatusIcon';

const photoStyles = () =>
  createStyles({
    profilePhotoButton: {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      display: 'block',
      marginBottom: 0,
      marginLeft: -16,
      marginRight: -16,
      marginTop: -10,
      outline: 'none',
      paddingBottom: 10,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 10,
      position: 'relative',

      '&::after': {
        backgroundColor: Colors.ChartGray,
        content: '""',
        height: 1,
        left: 0,
        position: 'absolute',
        right: 0,
        top: '100%',
      },
    },
    profilePhoto: {
      background: Colors.BoulderBlue,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      borderColor: Colors.BoulderBlue,
      borderRadius: 98,
      borderStyle: 'solid',
      borderWidth: 2,
      boxSizing: 'border-box',
      color: Colors.White,
      display: 'block',
      // 98: the 130px sidebar width, minus the 16px left + right padding
      height: 98,
      imageResolution: '30dpi',
      margin: 0,
      // 98: the 130px sidebar width, minus the 16px left + right padding
      width: 98,
      // `cover` will center the image in the provided space and crop any overflow
      objectFit: 'cover',
    },
    embiggenedProfilePhoto: {
      display: 'block',
      height: 'auto',
      maxWidth: '100%',
      width: '100%',
    },
  });

export type ProfileAvatarProps = {
  altText: string;
  classes: ClassNameMap;
  photoUrl: string;
  verificationStatus?: VerificationStatus | null;
};

export const ProfileAvatar = withStyles(photoStyles)(({
  altText,
  classes,
  photoUrl,
  verificationStatus,
}: ProfileAvatarProps) => {
  const [embiggenProfile, setEmbiggenProfile] = useState(false);

  return (
    <>
      <button
        type="button"
        className={`${classes.profilePhotoButton}`}
        onClick={() => setEmbiggenProfile(true)}
      >
        <VerificationStatusIcon status={verificationStatus} />
        <figure
          className={classes.profilePhoto}
          style={{ backgroundImage: `url(${photoUrl})` }}
          title={altText}
        />
      </button>
      <Dialog open={embiggenProfile} onClose={() => setEmbiggenProfile(false)}>
        <DialogContent>
          <img
            data-testid="profile-avatar-large"
            className={classes.embiggenedProfilePhoto}
            src={photoUrl}
            alt={altText}
          />
        </DialogContent>
      </Dialog>
    </>
  );
});
