import { IConfigCatClient, LogLevel, User } from 'configcat-common';
import * as configcat from 'configcat-js';

import { getDevFeatureFlags, getUrlOverrideFeatureFlags } from 'src/featureFlags/devFlags';
import { isLocalhost } from 'src/shared/util/helpers';

class ConfigCatFlags extends EventTarget {
  private flags: Record<string, boolean> = {};

  private userObject: User | undefined;

  private configCatClient: IConfigCatClient | undefined;

  constructor() {
    super();

    if (!process.env.REACT_APP_CONFIG_CAT_API_KEY) {
      console.error('Config cat key not set');
    } else {
      const sdkKey = process.env.REACT_APP_CONFIG_CAT_API_KEY;

      const logger = configcat.createConsoleLogger(LogLevel.Warn);

      this.configCatClient = configcat.createClientWithAutoPoll(sdkKey, {
        logger,
        configChanged: () =>
          this.getAllFlagValues().then(this.setLatestFlags.bind(this)).catch(console.error),
      });

      this.getAllFlagValues().then(this.setLatestFlags.bind(this)).catch(console.error);
    }
  }

  get latestFlags() {
    return this.flags;
  }

  private setLatestFlags(flags) {
    this.flags = flags;
    this.dispatchEvent(new CustomEvent('change', { detail: flags }));
  }

  setUserObject = async (user?: User) => {
    this.userObject = user;
    this.setLatestFlags(await this.getAllFlagValues());
  };

  private async getAllFlagValues(): Promise<Record<string, boolean>> {
    if (!this.configCatClient) {
      console.error('Config cat client not initialized');
      return {};
    }
    const devFlags = getDevFeatureFlags();
    const overriddenFlags = getUrlOverrideFeatureFlags();
    const flagValues = (
      await this.configCatClient.getAllValuesAsync(this.userObject ?? { identifier: '' })
    ).reduce(
      (accum, { settingKey, settingValue }) => ({
        ...accum,
        [settingKey]: overriddenFlags[settingKey] ?? devFlags[settingKey] ?? settingValue ?? false,
      }),
      {},
    );

    if (isLocalhost) {
      console.info('Feature flags', flagValues);
    }

    return flagValues;
  }
}

/**
 * This is an event target that will dispatch 'change' events when the feature flags change.
 * The event detail will be the new feature flags.
 *
 * e.g. `configCatFlags.addEventListener('change', (e: CustomEvent) => console.log(e.detail));`
 */
export const configCatFlags = new ConfigCatFlags();
