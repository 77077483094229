import { Button, Paper, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useState } from 'react';
import useSWR from 'swr';

import ErrorAlert from 'src/components/general/ErrorAlert';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import { GET_CURRENT_SCHEDULE } from 'src/dropInClinic/queries/getCurrentSchedule.gql';
import { SAVE_SCHEDULE } from 'src/dropInClinic/queries/saveSchedule.gql';
import { GetCurrentScheduleQuery } from 'src/generated/gql/graphql';

export const ClinicSchedule = () => {
  const { data, error, mutate } = useSWR<GetCurrentScheduleQuery>(GET_CURRENT_SCHEDULE);

  const { apolloClient } = useContext(ApolloClientContext);
  const [saveScheduleError, setSaveScheduleError] = useState(null);

  const saveSchedule = useCallback(
    async (schedule: unknown) => {
      setSaveScheduleError(null);
      if (!apolloClient) {
        return;
      }

      try {
        await apolloClient.mutate<void>({
          mutation: SAVE_SCHEDULE,
          variables: { schedule },
        });
      } catch (e) {
        setSaveScheduleError(e);
      }
      mutate();
    },
    [apolloClient, setSaveScheduleError],
  );

  if (error) {
    return <ErrorAlert message="Error fetching current schedule" error={error} />;
  }

  if (!data) {
    return <div>Loading current schedule</div>;
  }

  return (
    <div>
      {saveScheduleError && (
        <ErrorAlert message="Error updating request" error={saveScheduleError} />
      )}
      <Button variant="contained" onClick={() => mutate()}>
        Refresh Schedule
      </Button>
      <Button variant="contained" onClick={() => saveSchedule(data.getCurrentSchedule)}>
        Save Current Schedule
      </Button>
      <Paper>
        <Typography variant="h6">Schedule Map</Typography>
        <pre>{JSON.stringify(data.getCurrentSchedule, null, 2)}</pre>
      </Paper>
    </div>
  );
};
